import React, { PureComponent } from 'react'
import Header from '../Header/Header'
import { compose } from 'recompose'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import styles from './App.styles'
import Footer from '../Footer/Footer'
import Banner from '../Banner/Banner'
import FaucetContainer from '../../containers/FaucetContainer/FaucetContainer'
import Divider from '@material-ui/core/Divider/Divider'

interface IAppProps extends WithStyles {}

class App extends PureComponent<IAppProps> {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.app}>
        <div>
          <div className={classes.container}>
            <Header />
          </div>
        </div>
        <Banner />
        <div className={`${classes.container} ${classes.faucet}`}>
          <FaucetContainer />
        </div>
        <div>
          <Divider variant="middle" />
          <div className={classes.container}>
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}

export default compose<IAppProps, {}>(withStyles(styles))(App)
