import createStyles from '@material-ui/core/styles/createStyles'
import { Theme } from '@material-ui/core'
import { createPixelString } from 'react-utils/build/styling'

export default ({ spacing, breakpoints }: Theme) =>
  createStyles({
    form: {
      margin: '24px 0',
      alignItems: 'flex-start'
    },
    green: {
      color: '#2B7800'
    },
    section: {
      marginBottom: '2em'
    },
    label: {
      padding: 0,
      marginRight: spacing.unit
    },
    icon: {
      position: 'relative',
      width: 20,
      height: 20,
      borderRadius: 3,
      marginLeft: 1,
      border: '1px solid #CDCDCD',
      backgroundColor: '#FCFCFC'
    },
    checkMark: {
      width: 7,
      border: 'solid black',
      height: 15,
      position: 'absolute',
      transform: 'rotate(45deg)',
      left: 6,
      top: 0,
      borderWidth: '0 2px 2px 0'
    },
    input: {
      padding: createPixelString((spacing.unit * 3) / 2, spacing.unit * 2),
      backgroundColor: '#FCFCFC',
      border: '1px solid #CDCDCD'
    },
    requestBtn: {
      '&[disabled]': {
        backgroundColor: 'rgba(255,230,11,0.5)'
      }
    }
  })
