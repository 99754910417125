import { defineMessages } from 'react-intl'

const prefix = 'App.components.RequestTokens'

export default defineMessages({
  successTitle: {
    id: `${prefix}.successTitle`,
    defaultMessage: 'Authenticated!'
  },
  successDetails: {
    id: `${prefix}.successDetails`,
    defaultMessage: 'You are requesting ThunderCore (TT) with this email:'
  },
  description: {
    id: `${prefix}.description`,
    defaultMessage:
      'Enter your ThunderCore address* where you would like to receive tokens. Please note that you may only request once.'
  },
  caption: {
    id: `${prefix}.caption`,
    defaultMessage:
      '*Your Ethereum Wallet Address can be used as your ThunderCore address.'
  },
  placeholder: {
    id: `${prefix}.placeholder`,
    defaultMessage: 'Your ThunderCore address...'
  },
  agreement: {
    id: `${prefix}.agreement`,
    defaultMessage:
      'I have read and accept all terms in the {link1} and {link2}'
  },
  userAgree: {
    id: `${prefix}.userAgree`,
    defaultMessage: 'User Agreement'
  },
  poc: {
    id: `${prefix}.poc`,
    defaultMessage: 'Privacy Policy'
  },
  back: {
    id: `${prefix}.back`,
    defaultMessage: 'Back'
  },
  request: {
    id: `${prefix}.request`,
    defaultMessage: 'Request Thunder Token'
  }
})
