export enum ErrorCodes {
  RateLimit = 'RateLimit',
  InvalidEmail = 'InvalidEmail',
  DailyLimit = 'DailyLimit',
  InvalidRecaptcha = 'InvalidRecaptcha',
  Unauthorized = 'Unauthorized',
  InvalidRequest = 'InvalidRequest',
  NotFound = 'NotFound',
  Unhealthy = 'Unhealthy',
  Internal = 'Internal'
}
