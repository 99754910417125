import createStyles from '@material-ui/core/styles/createStyles'
import { Theme } from '@material-ui/core'

export default ({ palette }: Theme) =>
  createStyles({
    Banner: {
      padding: 24,
      backgroundColor: palette.grey.A400,
      textAlign: 'center',
      color: 'white'
    }
  })
