import * as React from 'react'
import { compose } from 'recompose'
import autobind from 'autobind-decorator'
import { injectIntl, InjectedIntlProps } from 'react-intl'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import styles from './Header.styles'
import titleImg from '../../assets/title.png'

interface IHeaderProps extends IHeaderOwnProps, WithStyles, InjectedIntlProps {}

interface IHeaderOwnProps {}

@autobind
export class UnconnectedHeader extends React.PureComponent<IHeaderProps> {
  render() {
    const { classes } = this.props
    return (
      <header className={classes.Header}>
        <img src={titleImg} alt="" />
      </header>
    )
  }
}

export default compose<IHeaderProps, IHeaderOwnProps>(
  injectIntl,
  withStyles(styles)
)(UnconnectedHeader)
