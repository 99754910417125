interface IWindow extends Window {
  thunderEnv?: {
    firebase: {
      apiKey: string
      authDomain: string
      databaseURL: string
      projectId: string
      storageBucket: string
      messagingSenderId: string
    }
    app: {
      recaptchaKey: string
    }
  }
}

const config = (window as IWindow).thunderEnv || {
  firebase: {
    apiKey: 'AIzaSyB_pP09oZGjcAJvs7yV6ypPIWqZLP-V-cM',
    authDomain: 'hubbit-dev.firebaseapp.com',
    databaseURL: 'https://hubbit-dev.firebaseio.com',
    projectId: 'hubbit-dev',
    storageBucket: 'hubbit-dev.appspot.com',
    messagingSenderId: '502457747496'
  },
  app: {
    recaptchaKey: '6LcS5pMUAAAAAN6FSl9tjL214K_pxlutZ4ax-TFZ'
  }
}

export default config
