import createStyles from '@material-ui/core/styles/createStyles'
import { Theme } from '@material-ui/core'

// @ts-ignore
export default ({ breakpoints }: Theme) =>
  createStyles({
    flex: {
      flex: 1
    },
    grid: {
      [breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    }
  })
