import React from 'react'
import { firebase } from '@firebase/app'
import '@firebase/auth'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import CssBaseline from '@material-ui/core/CssBaseline'
import App from './App/components/App/App'
import config from './config'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import theme from './theme'
import ReactGA from 'react-ga'
import { TrackJS } from 'trackjs'

TrackJS.install({
  token: '620d9efd8d29461b82c2d3f4ed5719fc'
})

ReactGA.initialize('UA-118758464-10')
ReactGA.pageview('/')

firebase.initializeApp(config.firebase)

const render = (Component: React.ComponentClass<any>) => {
  ReactDOM.render(
    <MuiThemeProvider theme={theme}>
      <CssBaseline>
        <IntlProvider locale="en">
          <BrowserRouter>
            <Component />
          </BrowserRouter>
        </IntlProvider>
      </CssBaseline>
    </MuiThemeProvider>,
    document.getElementById('root')
  )
}

render(App)

// Hot  Module Reload
if (module.hot) {
  module.hot.accept('./App/components/App/App', () => {
    const NextApp = require('./App/components/App/App').default
    render(NextApp)
  })
}
