import { defineMessages } from 'react-intl'
import { ErrorCodes } from '../../constants/ErrorCodes'

const prefix = 'App.containers.FaucetContainer'

export default defineMessages({
  [ErrorCodes.Internal]: {
    id: `${prefix}.${ErrorCodes.Internal}`,
    defaultMessage: 'Server error. Please try again.'
  },
  [ErrorCodes.DailyLimit]: {
    id: `${prefix}.${ErrorCodes.DailyLimit}`,
    defaultMessage: 'Daily limit reached. Please try again the next day.'
  },
  [ErrorCodes.InvalidEmail]: {
    id: `${prefix}.${ErrorCodes.InvalidEmail}`,
    defaultMessage:
      'Only @gmail.com accounts are supported. Please go back and try again.'
  },
  [ErrorCodes.RateLimit]: {
    id: `${prefix}.onceOnly`,
    defaultMessage: `You've already requested Thunder Token with this account.`
  }
})
