import createStyles from '@material-ui/core/styles/createStyles'
import { Theme } from '@material-ui/core'
import { createPixelString } from 'react-utils/build/styling'

export default ({ palette, spacing, breakpoints }: Theme) =>
  createStyles({
    Faucet: {
      [breakpoints.down('sm')]: {
        flexDirection: 'column'
      },
      lineHeight: 1.4,
      '& img': {
        maxHeight: 386
      }
    },
    section: {
      margin: createPixelString(spacing.unit * 2, 0)
    },
    google: {
      marginBottom: spacing.unit * 5
    },
    button: {
      padding: '0 16px',
      backgroundColor: palette.background.default
    },
    buttonText: {
      fontSize: '1.3em',
      color: palette.grey.A400,
      fontWeight: 500,
      textTransform: 'capitalize'
    },
    image: {
      marginRight: 16,
      width: 24
    },
    flex: {
      flex: 1
    }
  })
