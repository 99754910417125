import createStyles from '@material-ui/core/styles/createStyles'
import { Theme } from '@material-ui/core'

export default ({  }: Theme) =>
  createStyles({
    Header: {
      '& img': {
        maxHeight: 40
      }
    }
  })
