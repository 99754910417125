import * as React from 'react'
import { compose } from 'recompose'
import autobind from 'autobind-decorator'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import styles from './Error.styles'
import { Snackbar } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/Error'

interface IErrorProps extends IErrorOwnProps, WithStyles {}

interface IErrorOwnProps {
  error?: string
  onClose(): any
}

@autobind
export class UnconnectedError extends React.PureComponent<IErrorProps> {
  render() {
    const { classes, error, onClose } = this.props
    return (
      <Snackbar
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        open={!!error}
        onClose={onClose}
      >
        <SnackbarContent
          className={classes.content}
          classes={{
            message: classes.flex
          }}
          message={
            <Grid container={true} alignItems="center" className={classes.flex}>
              <Grid item={true}>
                <ErrorIcon className={classes.icon} />
              </Grid>
              <Grid item={true} className={classes.flex}>
                {error}
              </Grid>
            </Grid>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </Snackbar>
    )
  }
}

export default compose<IErrorProps, IErrorOwnProps>(withStyles(styles))(
  UnconnectedError
)
