import createStyles from '@material-ui/core/styles/createStyles'
import { Theme } from '@material-ui/core'

export default ({ spacing }: Theme) =>
  createStyles({
    ErrorWrapper: {
      minHeight: spacing.unit * 8
    },
    content: {
      backgroundColor: '#d32f2f'
    },
    icon: {
      display: 'block',
      marginRight: spacing.unit
    },
    flex: {
      flex: 1,
      wordBreak: 'break-all'
    }
  })
