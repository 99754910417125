import * as React from 'react'
import { compose } from 'recompose'
import autobind from 'autobind-decorator'
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl'
import messages from './messages'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import styles from './Faucet.styles'
import Grid from '@material-ui/core/Grid/Grid'
import faucetImg from '../../assets/faucet.png'
import { Typography } from '@material-ui/core'

interface IFaucetProps extends IFaucetOwnProps, WithStyles, InjectedIntlProps {}

interface IFaucetOwnProps {}

@autobind
export class UnconnectedFaucet extends React.PureComponent<IFaucetProps> {
  render() {
    const { classes } = this.props
    return (
      <Grid
        container={true}
        justify="center"
        alignItems="center"
        className={classes.Faucet}
        spacing={32}
      >
        <Grid item={true}>
          <img src={faucetImg} alt="" />
        </Grid>
        <Grid item={true} className={classes.flex}>
          <Typography variant="h3" gutterBottom={true}>
            <FormattedMessage {...messages.title2} />
          </Typography>
          <Typography paragraph={true}>
            <FormattedMessage
              {...messages.details}
              values={{
                link1: <a href="https://buy.thundercore.com/">here</a>,
                link2: <a href="https://ttswap.space">TTSwap</a>,
                link3: (
                  <a href="https://swap.swft.pro">SWFT</a>
                ),
                link4: <a href="https://hub.thundercore.com/">TT Wallet</a>,
                link5: <a href="https://laserswap.finance/swap">LaserSwap</a>,
                link6: <a href="https://app.xy.finance/">XY Finance</a>
              }}
            />
          </Typography>
          <Typography variant="h3" gutterBottom={true}>
            <FormattedMessage {...messages.title3} />
          </Typography>
          <Typography paragraph={true}>
            <FormattedMessage
              {...messages.explanation}
              values={{
                link: (
                  <a href="https://faucet-testnet.thundercore.com/">
                    https://faucet-testnet.thundercore.com/
                  </a>
                )
              }}
            />
          </Typography>
          <Typography variant="h3" gutterBottom={true}>
            <FormattedMessage {...messages.title} />
          </Typography>
          <Typography paragraph={true}>
            <FormattedMessage
              {...messages.action}
              values={{
                link: (
                  <a href="https://mainnet-rpc.thundercore.com">
                    https://mainnet-rpc.thundercore.com
                  </a>
                )
              }}
            />
          </Typography>
          <Typography paragraph={true}>
            <FormattedMessage
              {...messages.instructions}
              values={{
                link: (
                  <a href="https://help.thundercore.com/hc/en-us/categories/900001382983-Wallet">
                    wallet instructions
                  </a>
                )
              }}
            />
          </Typography>
        </Grid>
      </Grid>
    )
  }
}

export default compose<IFaucetProps, IFaucetOwnProps>(
  injectIntl,
  withStyles(styles)
)(UnconnectedFaucet)
