import * as React from 'react'
import { compose } from 'recompose'
import autobind from 'autobind-decorator'
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl'
import messages from './messages'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import styles from './Banner.styles'
import Typography from '@material-ui/core/Typography/Typography'

interface IBannerProps extends IBannerOwnProps, WithStyles, InjectedIntlProps {}

interface IBannerOwnProps {}

@autobind
export class UnconnectedBanner extends React.PureComponent<IBannerProps> {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.Banner}>
        <Typography color="inherit" variant="h2">
          <FormattedMessage {...messages.banner} />
        </Typography>
      </div>
    )
  }
}

export default compose<IBannerProps, IBannerOwnProps>(
  injectIntl,
  withStyles(styles)
)(UnconnectedBanner)
