import { defineMessages } from 'react-intl'

const prefix = 'App.components.Success'

export default defineMessages({
  message: {
    id: `${prefix}.message`,
    defaultMessage: `has been transferred to your ThunderCore wallet address.
        Below is the transaction hash, and you can click to view the transaction
        on ThunderScan:`
  },
  title: {
    id: `${prefix}.title`,
    defaultMessage: '{amt} Thunder Token'
  },
  success: {
    id: `${prefix}.success`,
    defaultMessage: 'Request successful!'
  },
  btn: {
    id: `${prefix}.btn`,
    defaultMessage: 'Migrate DApps to ThunderCore!'
  }
})
