import { defineMessages } from 'react-intl'

const prefix = 'App.components.Footer'

export default defineMessages({
  userAgreement: {
    id: `${prefix}.userAgreement`,
    defaultMessage: 'User Agreement'
  },
  privacy: {
    id: `${prefix}.privacy`,
    defaultMessage: 'Privacy Policy'
  },
  rights: {
    id: `${prefix}.rights`,
    defaultMessage: 'Ⓒ All Rights Reserved by Thunder Token Ltd.'
  }
})
