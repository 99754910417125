import * as React from 'react'
import { compose } from 'recompose'
import autobind from 'autobind-decorator'
import { TrackJS } from 'trackjs'
import Faucet from '../../components/Faucet/Faucet'
import firebase from '@firebase/app'
import RequestTokens from '../../components/RequestTokens/RequestTokens'
import axios from 'axios'
import Success from '../../components/Success/Success'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import messages from './messages'
import ReactGA from 'react-ga'
import { ErrorCodes } from '../../constants/ErrorCodes'
import ErrorComponent from '../../components/Error/Error'

interface IFaucetContainerProps
  extends IFaucetContainerOwnProps,
    InjectedIntlProps {}

interface IFaucetContainerOwnProps {}

const provider = new firebase.auth!.GoogleAuthProvider()
provider.setCustomParameters({ prompt: 'select_account' })

enum FaucetPages {
  Main,
  Authenticated,
  Result
}

interface IFaucetContainerState {
  amount?: string
  link?: string
  page: FaucetPages
  token?: string
  email?: string
  processing: boolean
  error?: string
}

@autobind
export class UnconnectedFaucetContainer extends React.PureComponent<
  IFaucetContainerProps,
  IFaucetContainerState
> {
  state: IFaucetContainerState = {
    processing: false,
    page: FaucetPages.Main
  }

  handleClose() {
    this.setState({ error: '' })
  }

  handleLogin() {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'Google Sign in State'
    })
    this.setState({ processing: true })
    firebase.auth!()
      .signInWithPopup(provider)
      .then(async ({ user }) => {
        if (!user) {
          throw new Error('No User')
        } else if (!user.email || !user.email.match('@gmail.com')) {
          throw new Error(this.props.intl.formatMessage(messages.InvalidEmail))
        } else {
          TrackJS.configure({
            userId: user.email
          })
          ReactGA.event({
            category: 'Navigation',
            action: 'Click',
            label: 'Google Authentication Success'
          })
          return this.setState({
            error: '',
            processing: false,
            token: await user.getIdToken(),
            page: FaucetPages.Authenticated,
            email: user.email
          })
        }
      })
      .catch(e => {
        ReactGA.event({
          category: 'Navigation',
          action: 'Click',
          label: 'Google Authentication Failed'
        })
        if (e && e.code !== 'auth/popup-closed-by-user') {
          this.setState({ processing: false, error: e.message })
        } else {
          this.setState({ processing: false })
        }
      })
  }

  handleRequest(address: string, captcha: string, reset: () => any) {
    this.setState({ processing: true })
    axios
      .post('/faucet/fund', {
        idToken: this.state.token,
        address,
        captcha
      })
      .then(({ data }) => {
        ReactGA.event({
          category: 'Button',
          action: 'Click',
          label: 'Request Success'
        })
        this.setState({
          amount: data.amount,
          link: data.thunderscanLink,
          processing: false,
          page: FaucetPages.Result,
          error: ''
        })
      })
      .catch(e => {
        const response = e.response
        if (response) {
          if (response.data.error.code === ErrorCodes.RateLimit) {
            ReactGA.event({
              category: 'Button',
              action: 'Click',
              label: 'Request Failed Daily Limit'
            })
          } else if (response.data.error.code === ErrorCodes.DailyLimit) {
            ReactGA.event({
              category: 'Button',
              action: 'Click',
              label: 'Request Failed Already Requested'
            })
          }
        }

        reset()
        const errorMessage = response
          ? this.props.intl.formatMessage(messages[response.data.error.code])
          : e.message

        this.setState({
          processing: false,
          error: errorMessage
        })
      })
  }

  reset() {
    firebase.auth!()
      .signOut()
      .then(() => {
        this.setState({
          page: FaucetPages.Main,
          error: ''
        })
      })
  }

  renderPage() {
    const { page, email, processing } = this.state
    switch (page) {
      case FaucetPages.Main:
        return <Faucet />
      case FaucetPages.Authenticated:
        return (
          <RequestTokens
            disabled={processing}
            email={email!}
            onRequest={this.handleRequest}
            onBack={this.reset}
          />
        )
      case FaucetPages.Result:
        return (
          <Success
            amount={this.state.amount!}
            thunderscanLink={this.state.link!}
          />
        )
    }
  }

  render() {
    return (
      <>
        <ErrorComponent error={this.state.error} onClose={this.handleClose} />
        <div style={{ paddingTop: 48 }}>{this.renderPage()}</div>
      </>
    )
  }
}

export default compose<IFaucetContainerProps, IFaucetContainerOwnProps>(
  injectIntl
)(UnconnectedFaucetContainer)
