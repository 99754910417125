import createStyles from '@material-ui/core/styles/createStyles'
import { Theme } from '@material-ui/core'

export default ({ spacing, palette, breakpoints }: Theme) =>
  createStyles({
    '@global': {
      a: {
        textDecoration: 'none',
        color: palette.secondary.main
      },
      html: {
        fontFamily:
          '"SF Pro Display", "Roboto", "Helvetica", "Arial", sans-serif',
        height: '100%'
      },
      body: {
        height: '100%'
      },
      '#root': {
        height: '100%'
      },
      img: {
        maxWidth: '100%'
      }
    },
    app: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    container: {
      [breakpoints.down('xs')]: {
        padding: 2 * spacing.unit
      },
      maxWidth: 850,
      padding: 4 * spacing.unit,
      margin: 'auto'
    },
    faucet: {
      paddingTop: 0,
      flex: '1 0'
    }
  })
