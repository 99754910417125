import { defineMessages } from 'react-intl'

const prefix = 'App.components.Faucet'

export default defineMessages({
  title: {
    id: `${prefix}.title`,
    defaultMessage: 'Get a Wallet Address for Your ThunderCore (TT)'
  },
  action: {
    id: `${prefix}.action`,
    defaultMessage: `Before you begin, you'll need a wallet address that supports TT. Any address created for Ethereum will work on ThunderCore. You can use MetaMask and any other popular Ethereum wallet to create ThunderCore address.
       To access the ThunderCore blockchain all you have to do is to point the network RPC to {link} instead of to the Ethereum network.`
  },
  instructions: {
    id: `${prefix}.instructions`,
    defaultMessage: `For detailed instructions, please check out: {link}`
  },
  details: {
    id: `${prefix}.details`,
    defaultMessage: `To get some TT on the mainnet, you can download the {link4} and create an account to claim the newbie sign-up bonus rewards to get some TT. Alternatively, you can buy some TT via credit card {link1}, you can also get TT via {link2}, {link5} DEX, Kucoin, Huobi, Upbit, Gate.io exchanges or {link3}, {link6} cross chain swap.`
  },
  title2: {
    id: `${prefix}.title2`,
    defaultMessage: 'Getting ThunderCore (TT)'
  },
  title3: {
    id: `${prefix}.title3`,
    defaultMessage: 'Getting Testnet Tokens'
  },
  explanation: {
    id: `${prefix}.explanation`,
    defaultMessage: `If you would like to get some Testnet Tokens, you may claim it from the Testnet faucet: {link}`
  }
})
