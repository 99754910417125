import { defineMessages } from 'react-intl'

const prefix = 'App.components.Banner'

export default defineMessages({
  banner: {
    id: `${prefix}.banner`,
    defaultMessage: 'ThunderCore (TT) Faucet'
  }
})
