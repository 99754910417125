import createMuiTheme from '@material-ui/core/styles/createMuiTheme'

export default createMuiTheme({
  breakpoints: {
    values: {
      xs: 340,
      sm: 490,
      md: 800,
      lg: 1000,
      xl: 1200
    }
  },
  palette: {
    background: {
      default: '#fff'
    },
    primary: {
      main: '#ffe81c'
    },
    secondary: {
      main: '#0844D2'
    }
  },
  typography: {
    useNextVariants: true,
    h1: {
      fontWeight: 'bold',
      fontSize: '2em'
    },
    h2: {
      fontWeight: 'bold',
      fontSize: '1.5em'
    },
    h3: {
      fontWeight: 'bold',
      fontSize: '1.1em'
    },
    h6: {
      fontWeight: 'bold',
      lineHeight: 1.2,
      fontSize: '1em'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        minWidth: 160,
        minHeight: 50,
        padding: '0 44px',
        fontWeight: 'bold',
        fontSize: '1em',
        textTransform: 'initial'
      }
    }
  }
})
