import createStyles from '@material-ui/core/styles/createStyles'
import { Theme } from '@material-ui/core'

export default ({  }: Theme) =>
  createStyles({
    wordWrap: {
      wordBreak: 'break-all'
    },
    img: {
      marginBottom: '1em'
    },
    imageContainer: {
      paddingTop: '1em',
      textAlign: 'center',
      color: '#417505',
      marginBottom: '1.8em'
    },
    section: {
      marginBottom: '3em'
    }
  })
