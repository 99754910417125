import * as React from 'react'
import { compose } from 'recompose'
import autobind from 'autobind-decorator'
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl'
import messages from './messages'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import styles from './Footer.styles'
import Grid from '@material-ui/core/Grid/Grid'
import Typography from '@material-ui/core/Typography/Typography'

interface IFooterProps extends IFooterOwnProps, WithStyles, InjectedIntlProps {}

interface IFooterOwnProps {}

@autobind
export class UnconnectedFooter extends React.PureComponent<IFooterProps> {
  render() {
    const { classes } = this.props
    return (
      <footer className={classes.Footer}>
        <Grid container={true} spacing={16} className={classes.grid}>
          <Grid item={true} className={classes.flex}>
            <Typography variant="subtitle2">
              <FormattedMessage {...messages.rights} />
            </Typography>
          </Grid>
          <Grid item={true}>
            <a
              href="https://www.thundercore.com/t&c.pdf"
              target="_blank"
            >
              <Typography variant="subtitle2" color="secondary">
                <FormattedMessage {...messages.userAgreement} />
              </Typography>
            </a>
          </Grid>
          <Grid item={true}>
            <a
              href="https://www.thundercore.com/privacy_policy.pdf"
              target="_blank"
            >
              <Typography variant="subtitle2" color="secondary">
                <FormattedMessage {...messages.privacy} />
              </Typography>
            </a>
          </Grid>
        </Grid>
      </footer>
    )
  }
}

export default compose<IFooterProps, IFooterOwnProps>(
  injectIntl,
  withStyles(styles)
)(UnconnectedFooter)
