import * as React from 'react'
import { compose } from 'recompose'
import autobind from 'autobind-decorator'
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl'
import messages from './messages'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import styles from './Success.styles'
import successImg from '../../assets/success.png'
import Typography from '@material-ui/core/Typography/Typography'
import Grid from '@material-ui/core/Grid/Grid'
import Button from '@material-ui/core/Button/Button'
import { toEther } from '../../utils/toEther'

interface ISuccessProps
  extends ISuccessOwnProps,
    WithStyles,
    InjectedIntlProps {}

interface ISuccessOwnProps {
  thunderscanLink: string
  amount: string
}

@autobind
export class UnconnectedSuccess extends React.PureComponent<ISuccessProps> {
  render() {
    const { classes, thunderscanLink, amount } = this.props

    return (
      <div className={classes.Success}>
        <div className={classes.imageContainer}>
          <img src={successImg} alt="" className={classes.img} />
          <Typography color="inherit" variant="h3">
            <FormattedMessage {...messages.success} />
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="body1" gutterBottom={true}>
            <b>
              <FormattedMessage
                {...messages.title}
                values={{ amt: toEther(amount, 2) }}
              />{' '}
            </b>
            <FormattedMessage {...messages.message} />
          </Typography>
          <Typography className={classes.wordWrap}>
            <a href={thunderscanLink}>{thunderscanLink}</a>
          </Typography>
        </div>
        <Grid container={true} alignItems="center" justify="center">
          <Button
            variant="contained"
            href="https://www.thundercore.com/migrate-ethereum-dapps"
            className={classes.btn}
            color="primary"
          >
            <FormattedMessage {...messages.btn} />
          </Button>
        </Grid>
      </div>
    )
  }
}

export default compose<ISuccessProps, ISuccessOwnProps>(
  injectIntl,
  withStyles(styles)
)(UnconnectedSuccess)
