import * as React from 'react'
import { compose } from 'recompose'
import autobind from 'autobind-decorator'
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl'
import messages from './messages'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import styles from './RequestTokens.styles'
import Typography from '@material-ui/core/Typography/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import Grid from '@material-ui/core/Grid/Grid'
import { ChangeEvent } from 'react'
import Button from '@material-ui/core/Button/Button'
import { isAddress } from '../../utils/isAddress'
import TextField from '@material-ui/core/TextField/TextField'
import ReCAPTCHA from 'react-google-recaptcha'
import config from '../../../config'
import { parse } from 'query-string'
import { withWidth } from '@material-ui/core'

interface IRequestTokensProps
  extends IRequestTokensOwnProps,
    WithStyles,
    InjectedIntlProps {
  width: 'xs' | string
}

interface IRequestTokensOwnProps {
  email: string
  disabled: boolean
  onBack(): any
  onRequest(address: string, captcha: string, reset: () => any): any
}

interface IRequestTokensState {
  checked: boolean
  captcha?: string
  address: string
  blurred?: boolean
  error?: boolean
}

@autobind
export class UnconnectedRequestTokens extends React.PureComponent<
  IRequestTokensProps,
  IRequestTokensState
> {
  recaptchaRef = React.createRef<ReCAPTCHA>()

  state: IRequestTokensState = {
    checked: false,
    address: ''
  }

  componentDidMount(): void {
    const { address } = parse(location.search)
    if (address) {
      this.setState({
        address: address as string,
        error: !isAddress(address as string),
        blurred: true
      })
    }
  }

  componentDidUpdate(prevProps: Readonly<IRequestTokensProps>): void {
    if (
      (this.props.width !== 'xs' && prevProps.width === 'xs') ||
      (prevProps.width !== 'xs' && this.props.width === 'xs')
    ) {
      this.setState({ captcha: undefined })
    }
  }

  check() {
    this.setState({ checked: !this.state.checked })
  }

  change(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value
    this.setState({
      address: e.target.value,
      error: !isAddress(value || '')
    })
  }

  handleRequest() {
    this.props.onRequest(this.state.address, this.state.captcha!, () => {
      if (this.recaptchaRef.current) {
        this.recaptchaRef.current.reset()
      }
    })
  }

  handleBlur() {
    this.setState({ blurred: true })
  }

  handleRecaptchaChange(captcha: string | null) {
    this.setState({ captcha: captcha || '' })
  }

  render() {
    const { classes, email, intl, onBack, disabled, width } = this.props
    const { checked, address, error, blurred, captcha } = this.state
    const showError = error && blurred
    const submitEnabled = !error && !disabled && address && checked && captcha

    return (
      <div className={classes.RequestTokens}>
        <div className={classes.section}>
          <Typography variant="h3" paragraph={true} className={classes.green}>
            <FormattedMessage {...messages.successTitle} />
          </Typography>
          <Typography gutterBottom={true}>
            <FormattedMessage {...messages.successDetails} />
          </Typography>
          <Typography className={classes.green} gutterBottom={true}>
            <em>{email}</em>
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography>
            <FormattedMessage {...messages.description} />
          </Typography>
          <Typography paragraph={true} variant="caption" color="textSecondary">
            <FormattedMessage {...messages.caption} />
          </Typography>
          <TextField
            value={address}
            inputProps={{
              spellCheck: false
            }}
            variant="outlined"
            fullWidth={true}
            autoFocus={true}
            onBlur={this.handleBlur}
            error={showError}
            helperText={showError && 'Invalid Address'}
            onChange={this.change}
            placeholder={intl.formatMessage(messages.placeholder)}
          />
          <FormControlLabel
            className={classes.form}
            control={
              <Checkbox
                className={classes.label}
                icon={<div className={classes.icon} />}
                checkedIcon={
                  <div className={classes.icon}>
                    <div className={classes.checkMark} />
                  </div>
                }
                color="primary"
                checked={checked}
                onChange={this.check}
              />
            }
            label={
              <FormattedMessage
                {...messages.agreement}
                values={{
                  link1: (
                    <a
                      href="https://www.thundercore.com/termsandconditions"
                      target="_blank"
                    >
                      <FormattedMessage {...messages.userAgree} />
                    </a>
                  ),
                  link2: (
                    <a
                      href="https://www.thundercore.com/privacy-policy"
                      target="_blank"
                    >
                      <FormattedMessage {...messages.poc} />
                    </a>
                  )
                }}
              />
            }
          />
          <ReCAPTCHA
            key={width === 'xs' ? 'compact' : 'normal'}
            size={width === 'xs' ? 'compact' : 'normal'}
            ref={this.recaptchaRef}
            sitekey={config.app.recaptchaKey}
            onChange={this.handleRecaptchaChange}
          />
        </div>
        <Grid
          container={true}
          spacing={32}
          justify="center"
          alignItems="center"
        >
          <Grid item={true} xs={12} sm="auto">
            <Button
              fullWidth={true}
              color="primary"
              className={classes.requestBtn}
              size="large"
              variant="contained"
              disabled={!submitEnabled}
              onClick={this.handleRequest}
            >
              <FormattedMessage {...messages.request} />
            </Button>
          </Grid>
          <Grid item={true} xs={12} sm="auto">
            <Button
              fullWidth={true}
              variant="contained"
              size="large"
              className={classes.backBtn}
              onClick={onBack}
              disabled={disabled}
            >
              <FormattedMessage {...messages.back} />
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default compose<IRequestTokensProps, IRequestTokensOwnProps>(
  injectIntl,
  withStyles(styles),
  withWidth()
)(UnconnectedRequestTokens)
